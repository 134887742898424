<template>
  <div class="w-full h-full" id="bookSummery">
    <!-- header section -->
    <h3 class="mb-8 custHead">
      {{ $t(Title) }}
    </h3>
    <!-- body section -->
    <div class="vx-row  justify-center">
      <div
        class="vx-col lg:w-3/4  p-5"
        style="
          box-sizing: border-box;
          border-style: ridge;
          border: 2px ridge rgb(62, 201, 214) !important;
          border-radius: 9px;
        "
      >
        <!-- first section -->
        <vs-card>
          <div class="vx-row">
            <!-- doctorInfo for session -->
            <div v-if="flag == 1" class="vx-col lg:w-1/2">
              <div class="ml-5">
                <h4 class="mt-2 mb-3 custCardHead">{{ $t("DrInfo") }}</h4>
                <div class="vx-row">
                  <div class="vx-col mt-1">
                    <!-- DoctorPlaceholderImg -->
                    <imageLazy
                      :imageStyle="{ 'border-radius': '20px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + PatientReservationSession.Doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
                  </div>
                  <div class="vx-col mt-1">
                    <h4
                      class="mt-1 mb-2"
                      v-if="PatientReservationSession.Doctor.Name != null"
                    >
                      {{ $t("Dr") }}.
                      {{ PatientReservationSession.Doctor.Name }}
                    </h4>
                    <div class="mt-1 fontSizetext">
                      {{ $t("Address") }}:<span
                        class="fontMedium fontbold m-1"
                        v-if="PatientReservationSession.Doctor.Address"
                        >{{ PatientReservationSession.Doctor.Address }}</span
                      >
                    </div>
                    <div class="mt-1 fontSizetext">
                      {{ $t("Destination") }}:<span
                        class="fontMedium fontbold m-1"
                        v-if="PatientReservationSession.Doctor.Country"
                        >{{ PatientReservationSession.Doctor.Country.Name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- hospitalInfo for surgery -->
            <div v-if="flag == 2" class="vx-col lg:w-1/2">
              <div class="ml-5">
                <h4 class="mt-2 mb-3 custCardHead">{{ $t("HospitalInfo") }}</h4>
                <div class="vx-row">
                  <div
                    class="vx-col mt-1"
                    v-if="PatientReservationSurgery.HospitalImage != null"
                  >
                    <!-- HospitalPlaceholderImg -->
                    <imageLazy
                      :imageStyle="{ 'border-radius': '15px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + PatientReservationSurgery.HospitalImage"
                      placeHolderType="hospital"
                    />
                  </div>
                  <div class="vx-col mt-1">
                    <h4
                      class="mt-1 mb-2"
                      v-if="PatientReservationSurgery.HospitalName != null"
                    >
                      {{ PatientReservationSurgery.HospitalName }}
                    </h4>
                    <div class="mt-1 fontSizetext">
                      {{ $t("Address") }}:<span
                        class="fontMedium fontbold m-1"
                        v-if="PatientReservationSurgery.HospitalAddress"
                        >{{ PatientReservationSurgery.HospitalAddress }}</span
                      >
                    </div>
                    <div class="mt-1 fontSizetext">
                      {{ $t("Destination") }}:<span
                        class="fontMedium fontbold m-1"
                        v-if="PatientReservationSurgery.HospitalCountry"
                        >{{ PatientReservationSurgery.HospitalCountry }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- pationtInfo -->
            <div class="vx-col lg:w-1/2">
              <h4 class="mt-2 mb-3 custCardHead">{{ $t("PatientInfo") }}</h4>
              <div class="vx-row">
                <div class="vx-col mt-1" v-if="patientObj.ProfileImagePath != null">
                  <!-- PatientPlaceholderImg -->
                  <imageLazy
                    :imageStyle="{ 'border-radius': '15px' }"
                    :imageClass="'doctorImage'"
                    :src="baseURL + patientObj.ProfileImagePath"
                    placeHolderType="patient"
                  />
                </div>
                <div class="vx-col mt-1">
                 <h4 class="mt-1 fontSizetext">
                  {{ $t("Name") }}:<span v-if="patientObj.Name != null">
                      {{ patientObj.Name }}
                    </span>
                    <!-- <span v-if="patientObj.FamilyName != null">
                      {{ " " + patientObj.FamilyName }}
                    </span> -->
                  </h4>
           <!-- this same all lines like address<div class="mt-1 fontSizetext">
                  {{ $t("Name") }}:<span v-if="patientObj.Name != null">
                      {{ patientObj.Name }}
                    </span>
                    <span v-if="patientObj.FamilyName != null">
                      {{ " " + patientObj.FamilyName }}
                    </span>
                 </div> -->
                  <div class="mt-1 fontSizetext">
                    {{ $t("Address") }}:<span
                      class="fontMedium fontbold m-1"
                      v-if="patientObj.Address != null"
                      >{{ patientObj.Address }}</span
                    >
                  </div>
                  <div class="mt-1 fontSizetext">
                    {{ $t("Destination") }}:<span
                      class="fontMedium fontbold m-1"
                      v-if="patientObj.CountryName != null"
                      >{{ patientObj.CountryName }}</span
                    >
                  </div>
                  <div class="mt-1 fontSizetext">
                    {{ $t("PhoneNumber") }}:<span
                      class="fontMedium fontbold m-1"
                      v-if="patientObj.Phone != null && !$vs.rtl"
                      >+{{ patientObj.Phone }}</span
                    >

                    <span
                      class="fontMedium fontbold m-1"
                      v-if="patientObj.Phone != null && $vs.rtl"
                      >{{ patientObj.Phone }}+</span
                    >
                  </div>
                  <div class="mt-1 fontSizetext">
                    {{ $t("Email") }}:<span
                      class="fontMedium fontbold m-1"
                      v-if="patientObj.Email != null"
                      >{{ patientObj.Email }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vs-card>

        <!-- reservation second -->
        <vs-card class="mt-2 pb-10">
          <div class="vx-row">
            <div class="vx-col lg:w-1/3 md:w-1/2"></div>
            <div class="vx-col lg:w-1/3 md:w-1/4">
              <h4 class="mt-1 mb-3 custCardHead">
                {{ $t(TitleReservation) }}
              </h4>
            </div>
            <div class="vx-col lg:w-1/4 xl:w-1/4 md:w-1/4">
              <h4 class="mt-1 mb-3 custCardHead">
                {{ $t("PaymentInfo") }}
              </h4>
            </div>
          </div>
          <div class="vx-row">
            <!-- calender section -->
            <div class="vx-col lg:w-1/3">
              <div class="ml-5">
                <div class="vx-row w-full">
                  <!-- Session calender -->
                  <div v-if="flag == 1">
                    <v-date-picker
                      :locale="$vs.rtl ? 'ar' : 'en'"
                      style="width: 100%"
                      ref="datepicker2"
                      :value="calenderData"
                    />
                  </div>
                  <!-- surgery calender -->
                  <!-- class="lg:w-1/2" -->
                  <div v-if="flag == 2">
                    <v-date-picker
                      :locale="$vs.rtl ? 'ar' : 'en'"
                      style="width: 100%"
                       multiple="true"
                        is-range
                      ref="datepicker"
                      :value="ModelCal"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- operattionInfo -->
            <div class="vx-col lg:w-1/3 mt-5">
              <!-- SurgeryName -->
              <div
                class="mt-1 fontSizetext"
                v-if="flag == 2 && PatientReservationSurgery.SurgeryName != null"
              >
                <span class="fontbold fontMedium m-1">{{ $t("SurgeryName") }} :</span>
                {{ PatientReservationSurgery.SurgeryName }}
              </div>
              <!-- ReservitionDate -->
              <div class="mt-1 fontSizetext">
                <span class="fontbold fontMedium m-1">{{ $t("ReservitionDate") }}:</span>
                {{ changeFormatedDate(operationObj.CreatedDate) }}
              </div>
              <!-- SessionDate -->
              <div class="mt-1 fontSizetext" v-if="flag == 1">
                <span class="fontbold fontMedium m-1">{{ $t("SessionDate") }} :</span>

                {{ changeFormatedDate(PatientReservationSession.DoctorSession.DateFrom) }}
              </div>
              <!-- SurgeryDate -->
              <div class="mt-1 fontSizetext" v-if="flag == 2 && paymentObj.PaymentStatusID == 7">
              <span class="fontbold fontMedium m-1">{{ $t("SurgeryDate") }} :</span><br />
              {{ $t("From") }} :
              {{ changeFormatedDate(PatientReservationSurgery.SurgeryDateFrom) }}
              - <br />
              {{ $t("To") }} :
              {{ changeFormatedDate(PatientReservationSurgery.SurgeryDateTo) }}
            </div>

            <div class="mt-1 fontSizetext" v-if="flag == 2 && paymentObj.PaymentStatusID == 5">
              <span class="fontbold fontMedium m-1">{{ $t("SurgeryDate") }} :</span><br />
              {{ changeFormatedDate(PatientReservationSurgery.SurgeryDate) }}
            </div>

       

              <!-- DoctorSessionTime -->
              <div class="mt-1 fontSizetext" v-if="flag == 1">
                <span
                  class="m-1 fontbold fontMedium"
                  v-if="
                    PatientReservationSession.DoctorSession.DateFrom != null &&
                    PatientReservationSession.DoctorSession.DateTo != null
                  "
                  >{{ $t("From") }}:</span
                >

                {{ PatientReservationSession.DoctorSession.DateFrom.slice(11, 16) }}
                <span
                  class="fontbold fontMedium m-1"
                  v-if="
                    PatientReservationSession.DoctorSession.DateFrom != null &&
                    PatientReservationSession.DoctorSession.DateTo != null
                  "
                >
                  - {{ $t("To") }}:</span
                >
                {{ PatientReservationSession.DoctorSession.DateTo.slice(11, 16) }}
              </div>
              <!-- Status -->
              <div class="mt-1 fontSizetext">
                <span class="fontbold fontMedium m-1">{{ $t("Status") }}:</span>
                {{ operationObj.StatusName }}
              </div>
            </div>

            <!-- paymentInfo -->
            <div class="vx-col lg:w-1/4 mt-5">
              <div class="mt-1 fontSizetext">
                <span class="fontbold fontMedium m-1" v-if="flag ==1">{{ $t("Price") }}:</span>
                <span class="fontbold fontMedium m-1"v-else-if="flag == 2 && paymentObj.PaymentStatusID == 7">{{ $t("RemainingAmount") }}:</span>
                <span class="fontbold fontMedium m-1" v-else-if="flag == 2 && paymentObj.PaymentStatusID == 5">Total Paid:</span>
                {{ flag == 2 && paymentObj.PaymentStatusID == 5 ? paymentObj.price + ProcessingFees : paymentObj.price }}
              </div>
            
              <div class="mt-1 fontSizetext"  v-if="flag == 2 && paymentObj.PaymentStatusID == 7">
                <span class="fontbold fontMedium m-1">{{ $t("deposit") }}:</span>
                {{ ProcessingFees }}
              </div>

              <div class="mt-1 fontSizetext" v-if="flag == 1">
                <span class="fontbold fontMedium m-1">{{ $t("Currency") }}:</span>
                {{ paymentObj.CurrencyName }}
              </div>
              <div class="mt-1 fontSizetext">
                <span class="m-1 fontbold fontMedium">{{ $t("Status") }}:</span>

                {{ paymentObj.PaymentStatusName }}
              </div>
              <div class="mt-5 fontSizetext">
                <div
                  class="p-3"
                  v-if="paymentObj.PaymentStatusID == 1&&flag==1"
                  style="
                    box-sizing: border-box;
                    border-style: ridge;
                    border: 2px ridge #820032 !important;
                    color: #820032;
                    font-size: 16px;
                  "
                >
                  <span class="fontbold fontMedium m-1"
                    >{{ $t("PleaseNote") }}:
                    <br />
                  </span>
                  {{ $t("PendingNote") }}
                </div>
                <div
                  class="p-3"
                  v-if="
                    paymentObj.PaymentStatusID == 2 ||
                    paymentObj.PaymentStatusID == 5 ||
                    paymentObj.PaymentStatusID == 7
                  "
                  style="
                    box-sizing: border-box;
                    border-style: ridge;
                    border: 2px ridge #820032 !important;
                    color: #820032;
                    font-size: 14px;
                  "
                >
                  <span class="fontbold fontMedium m-1"
                    >{{ $t("PleaseNote") }}:
                    <br />
                  </span>
                  {{ $t("ConfirmNote") }}
                </div>
              </div>
            </div>
          </div>
        </vs-card>
      </div>
    </div>
    <!-- footer section -->
    <div class="flex flex-wrap justify-center">
      <vs-button
        class="sm:w-auto text-center mt-3 p-4 mr-12 pl-8 pr-8 fontSizetext"
        @click="ConvertToPDF"
        icon-pack="icon icon-download"
        icon="feather"
        v-if="operationObj.StatusID==2"
        color="rgb(62, 201, 214)"
        >{{ $t("DownloadBooking") }}
      </vs-button>
      <vs-button
        v-if="flag == 1"
        class="sm:w-auto text-center mt-3 p-4 mr-12 pl-8 pr-8 fontSizetext"
        color="rgb(62, 201, 214)"
        @click="OpenOnlineConsultations"
        >{{ $t("PreviewHistory") }}</vs-button
      >
      <vs-button
        class="sm:w-auto text-center mr-12 mt-3 pl-8 pr-8 fontSizetext"
        color="rgb(62, 201, 214)"
        @click="Back"
        >{{ $t("Back") }}</vs-button
      >
      <!-- v-if="paymentObj.PaymentStatusID == 5" -->
      <vs-button
        v-if="paymentObj.PaymentStatusID == 5"
        class="sm:w-auto text-center mt-3 pl-8 pr-8 fontSizetext"
        color="rgb(62, 201, 214)"
        @click="GeneratInvoice"
        >{{ $t("GeneratInvoice") }}</vs-button
      >
    </div>

    <!-- invoice section-->
    <div dir="ltr">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1500"
      filename="Invoice"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="Invoice2Pdf"
    >
      <section slot="pdf-content" dir="ltr" id="invoicePdf" class="m-12" style="width: 90%">
        <div class="canvImg">
          <!-- header -->
          <div class="vx-row">
            <div class="vx-col lg:w-1/2">
              <table>
                <tr class="desSendTable">
                  <td class="pt-3">
                    <h6 style="font-size: 1.4rem; color: black; opacity: 0.7">
                      Cure Journey Portal
                    </h6>
                  </td>
                </tr>
                <tr class="desSendTable">
                  <td>
                    <h6 style="font-size: 1.4rem; color: black; opacity: 0.7">
                     Souk Al Bahar, Saha offices C,
                    </h6>
                  </td>
                </tr>
                <tr class="desSendTable">
                  <td>
                    <h6 style="font-size: 1.4rem; color: black; opacity: 0.7">
                      office 202, Dubai,UAE
                    </h6>
                  </td>
                </tr>
              </table>
            </div>
            <div class="vx-col lg:w-1/2">
              <div style="text-align: right">
                \
                <!-- docliniaLogoPlaceholderImg -->
                <img
                  src="@/assets/images/logo/docliniaLogo.png"
                  style="width: 23rem; height: 7rem"
                  class="mx-auto m-3"
                />
              </div>
            </div>
          </div>

          <!-- title section -->
          <div class="flex flex-wrap justify-center mt-3">
            <div
              class="vx-row mt-8 pt-4 fontheader"
              style="background: #016a86; height: 5rem; width: 95%"
            >
              <div class="vx-col lg:w-3/5">
                <h2 style="color: white">
                 Invoice :
                  <labal v-if="flag == 1"
                    >INV-{{ this.PatientReservationSession.ReferenceNumber }}</labal
                  >
                  <labal v-else
                    >INV-{{ PatientReservationSurgery.ReferenceNumber }}</labal
                  >
                </h2>
              </div>

              <div class="vx-col lg:w-2/5">
                <div style="text-align: right">
                  <h2 style="color: white">
                    <label v-if="flag == 1">
                      {{
                        changeFormatedDate(
                          PatientReservationSession.DoctorSession.DateFrom
                        )
                      }}
                    </label>
                    <label v-else>
                      <label
                        style="color: #004477"
                        v-if="PatientReservationSurgery.SurgeryDate"
                      >
                        {{ changeFormatedDate(PatientReservationSurgery.SurgeryDate) }}
                      </label>
                    </label>
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <!-- body section -->
          <div class="flex flex-wrap justify-center mt-6">
            <div
              class="text-center h-full mt-3 pl-4 pr-4"
              style="min-height: 700px; width: 95%"
            >
              <!-- first section -->
              <div class="vx-row mt-3">
                <table style="width: 40%">
                  <tr class="fontheader" style="height: 2.5rem">
                    <th>
                      <h1 class="mb-3">
                        INVOICE TO
                      </h1>
                      <hr class="line mb-2" style="width: 80%" />
                    </th>
                  </tr>

                  <tr class="desSendTable" v-if="patientObj.Name">
                    <td class="pl-3 pr-3">
                      <h4 style="color: black">
                        <label style="color: #004477"> {{ this.patientObj.Name }}  {{ " " + this.patientObj.FamilyName }}</label>
                      </h4>
                    </td>
                  </tr>
                  <tr class="desSendTable" v-if="patientObj.Address" >
                    <td class="pl-3 pr-3">
                      <h4 style="color: black">
                        <label style="color: #004477">
                          {{ this.patientObj.Address }}</label>
                      </h4>
                    </td>
                  </tr>
                  <tr class="desSendTable" v-if="patientObj.CountryName" >
                    <td class="pl-3 pr-3">
                      <h4 style="color: black">
                        <label style="color: #004477">
                          {{ this.patientObj.CountryName }}</label
                        >
                      </h4>
                    </td>
                  </tr>
                </table>
              </div>

              <!-- secound section -->
              <div class="vx-row mt-12">
                <table
                  class="mt-12"
                  style="width: 100%; background-color: white  border: 1px solid black; "
                >
                  <tr
                    class="mb-12 pb-12"
                    style="background: #016a86; height: 5rem; width: 100%; color: white"
                  >
                    <th class="text-center">
                      <h1 style="color: white">
                       Service
                      </h1>
                    </th>
                    <th class="text-center">
                      <h1 style="color: white">
                       Description
                      </h1>
                    </th>
                    <th class="text-center">
                      <h1 style="color: white">
                        Total
                      </h1>
                    </th>
                  </tr>
                  <tr class="desSendTable pt-12 text-center">
                    <td class="pl-3 pr-3">
                      <h4 v-if="flag == 1" style="color: black">
                       Online Consultation
                      </h4>

                      <h4 v-else style="color: black">
                        Surgery / {{PatientReservationSurgery.SurgeryName}}
                      </h4>
                    </td>
                    <td class="pl-3 pr-3">
                      <h4 style="color: black" v-if="flag == 1">
                        Doctor's name:<label style="color: #004477">
                          {{ PatientReservationSession.Doctor.NameEN }}
                        </label>
                      </h4>
                      <h4 style="color: black" v-else>
                        Hospital's name:<label style="color: #004477">
                          {{ PatientReservationSurgery.HospitalNameEN }}</label
                        >
                      </h4>
                    </td>
                    <td class="pl-3 pr-3" v-if="flag == 1">
                      <h4 style="color: black">
                        {{ paymentObj.price - PatientReservationSession.VAT }}
                          USD
                      </h4>
                    </td>
                    <td class="pl-3 pr-3" v-else>
                      <h4 style="color: black">
                        {{
                          paymentObj.price +
                          ProcessingFees -
                          PatientReservationSurgery.VAT
                        }}
                        USD
                      </h4>
                    </td>
                  </tr>
                </table>
              </div>
              <hr class="line mb-2 mt-8" style="width: 100%" />

              <!-- third section -->
              <div class="vx-row mt-5 w-full">
                <div class="vx-col lg:w-3/5"></div>
                <div class="vx-col lg:w-2/5">
                  <table>
                    <!-- subTotal -->
                    <tr class="desSendTable">
                      <td class="pl-5 pr-5 pt-3">
                        <h4 style="color: black">
                            SUBTOTAL:<label
                            style="color: #004477"
                            v-if="this.flag == 1"
                          >
                            {{ paymentObj.price - PatientReservationSession.VAT }}
                            USD</label
                          >
                          <label style="color: #004477" v-else>
                            {{
                              paymentObj.price +
                              ProcessingFees -
                              PatientReservationSurgery.VAT
                            }}
                            USD</label
                          >
                        </h4>
                      </td>
                    </tr>
                    <!-- vat -->
                    <tr class="desSendTable">
                      <td class="pl-5 pr-5">
                        <h4 style="color: black">
                            VAT:<label
                            style="color: #004477"
                            v-if="this.flag == 1"
                          >
                            {{ PatientReservationSession.VAT }} USD</label
                          >
                          <label style="color: #004477" v-else>
                            {{ PatientReservationSurgery.VAT }} USD</label
                          >
                        </h4>
                      </td>
                    </tr>
                    <!-- total -->
                    <tr class="desSendTable">
                      <td class="pl-5 pr-5">
                        <h4 style="color: black" v-if="this.flag == 1">
                          TOTAL :<label style="color: #004477"
                            >{{ paymentObj.price }}USD</label
                          >
                        </h4>
                        <h4 style="color: black" v-else>
                          TOTAL :<label style="color: #004477"
                            >{{ paymentObj.price + ProcessingFees }}
                          USD</label
                          >
                        </h4>
                      </td>
                    </tr>
                    <!-- date -->
                    <tr class="desSendTable">
                      <td class="pl-5 pr-5">
                        <h5 style="color: black">
                        DATE :<label
                            style="color: #004477"
                            v-if="flag == 1"
                          >
                            {{ changeFormatedDate(operationObj.CreatedDate) }}</label
                          >
                          <label style="color: #004477" v-else>
                            <label
                              style="color: #004477"
                              v-if="PatientReservationSurgery.SurgeryDate"
                            >
                              {{
                                changeFormatedDate(PatientReservationSurgery.SurgeryDate)
                              }}
                            </label>
                            <label style="color: #004477" v-else>
                              {{
                                changeFormatedDate(PatientReservationSurgery.CreatedDate)
                              }}
                            </label>
                          </label>
                        </h5>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    </div>

    <!-- download section-->
    <div dir="ltr">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="BookingSummary"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" dir="ltr">
        <div class="w-full h-full">
          <h3 class="mb-8 custHead mr-12 ml-12" v-if="flag==1" style="margin-top: 5%">
           Appointment Reservation Summary
          </h3>
           <h3 class="mb-8 custHead mr-12 ml-12" v-if="flag==2" style="margin-top: 5%">
           Surgery Reservation Summary
          </h3>
          <!-- first section -->
          <vs-card class="ml-5">
            <div class="vx-row">
              <!-- doctorInfo for session -->
              <div v-if="flag == 1" class="vx-col lg:w-1/2">
                <div class="ml-5">
                  <h4 class="mt-2 mb-3 custCardHead">Doctor Info</h4>
                  <div class="vx-row">
                    <div class="vx-col mt-1">
                      <h4
                        class="mt-1 mb-2"
                        v-if="PatientReservationSession.Doctor.NameEN != null"
                      >Dr.
                        {{ PatientReservationSession.Doctor.NameEN }}
                      </h4>
                      <div class="mt-1 fontSizetext">
                         Address:<span
                          class="fontMedium fontbold m-1"
                          v-if="PatientReservationSession.Doctor.Address"
                          >{{ PatientReservationSession.Doctor.Address }}</span
                        >
                      </div>
                      <div class="mt-1 fontSizetext">
                       Destination:<span
                          class="fontMedium fontbold m-1"
                          v-if="PatientReservationSession.Doctor.Country"
                          >{{ PatientReservationSession.Doctor.Country.NameEN }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- hospitalInfo for surgery -->
              <div v-if="flag == 2" class="vx-col lg:w-1/2">
                <div class="ml-5">
                  <h4 class="mt-2 mb-3 custCardHead">
                       Hospital Info
                  </h4>
                  <div class="vx-row">
                    <div class="vx-col mt-1">
                      <h4
                        class="mt-1 mb-2"
                        v-if="PatientReservationSurgery.HospitalNameEN != null"
                      >
                        {{ PatientReservationSurgery.HospitalNameEN }}
                      </h4>
                      <div class="mt-1 fontSizetext">
                          Address:<span
                          class="fontMedium fontbold m-1"
                          v-if="PatientReservationSurgery.HospitalAddress"
                          >{{ PatientReservationSurgery.HospitalAddress }}</span
                        >
                      </div>
                      <div class="mt-1 fontSizetext">
                         Destination:<span
                          class="fontMedium fontbold m-1"
                          v-if="PatientReservationSurgery.HospitalCountryEN"
                          >{{ PatientReservationSurgery.HospitalCountryEN }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- pationtInfo -->
              <div class="vx-col lg:w-1/2">
                <h4 class="mt-2 mb-3 custCardHead">
                 Patient Info
                </h4>
                <div class="vx-row">
                  <div class="vx-col mt-1">
                    <h4 class="mt-1 mb-3">
                      <span v-if="patientObj.NameEN != null">
                        {{ patientObj.NameEN }}
                      </span>
                      <span v-if="patientObj.FamilyName != null">
                        {{ " " + patientObj.FamilyName }}
                      </span>
                    </h4>
                    <div class="mt-1 fontSizetext">
                         Address:<span
                        class="fontMedium fontbold m-1"
                        v-if="patientObj.Address != null"
                        >{{ patientObj.Address }}</span
                      >
                    </div>
                    <div class="mt-1 fontSizetext">
                        Destination:<span
                        class="fontMedium fontbold m-1"
                        v-if="patientObj.CountryNameEN != null"
                        >{{ patientObj.CountryNameEN }}</span
                      >
                    </div>
                    <div class="mt-1 fontSizetext">
                         Phone Number:<span
                        class="fontMedium fontbold m-1"
                        v-if="patientObj.Phone != null"
                        >{{ patientObj.Phone }}</span
                      >
                    </div>
                    <div class="mt-1 fontSizetext">
                       Email <span
                        class="fontMedium fontbold m-1"
                        v-if="patientObj.Email != null"
                        >{{ patientObj.Email }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vs-card>
          <!-- reservation second section -->
          <vs-card class="mt-2 ml-5 pb-10">
            <div class="vx-row">
              <div class="vx-col lg:w-1/4">
                <div class="ml-5">
                  <h4 class="mt-1 mb-3 custCardHead" v-if="flag==1">
                    Reservation Session
                  </h4>
                  <h4 class="mt-1 mb-3 custCardHead" v-if="flag==2">
                    Reservation Session
                  </h4>
                </div>
              </div>
              <div class="vx-col lg:w-1/4" style="margin-left: 10%"></div>
              <div class="vx-col lg:w-1/3">
                <h4 class="mt-1 mb-3 custCardHead">
                   Payment Info
                </h4>
              </div>
            </div>
            <div class="vx-row">
              <!-- date info -->
              <div class="vx-col lg:w-1/4">
                <div class="ml-1">
                  <div class="vx-row">
                    <div class="vx-col" v-if="flag == 1">
                      <v-date-picker

                        style="width: 100%"
                        v-model="calenderData"
                      />
                    </div>

                    <div class="vx-col" v-if="flag == 2">
                      <v-date-picker
                        ref="datepicker"
                        style="width: 100%"
                        multiple="true"
                        is-range
                        v-model="ModelCal"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- operattionInfo -->
              <div class="vx-col lg:w-1/4 mt-5" style="margin-left: 10%">
                <!-- SurgeryName -->
                <div
                  class="mt-1 fontSizetext"
                  v-if="flag == 2 && PatientReservationSurgery.SurgeryNameEN != null"
                >
                  <span class="fontbold fontMedium m-1">Surgery Name:</span>
                  {{ PatientReservationSurgery.SurgeryNameEN }}
                </div>
                <!-- ReservitionDate -->
                <div class="mt-1 fontSizetext">
                  <span class="fontbold fontMedium m-1"
                    >Reservition Date :</span
                  >
                  {{ changeFormatedDate(operationObj.CreatedDate) }}
                </div>
                <!-- SessionDate -->
                <div class="mt-1 fontSizetext" v-if="flag == 1">
                  <span class="fontbold fontMedium m-1">Session Date :</span>
                  {{
                    changeFormatedDate(PatientReservationSession.DoctorSession.DateFrom)
                  }}
                </div>
                <!-- SurgeryDate -->
                <div class="mt-1 fontSizetext" v-if="flag == 2">
                  <span class="fontbold fontMedium m-1">Surgery Date :</span
                  ><br />
                  From :
                  {{ changeFormatedDate(PatientReservationSurgery.SurgeryDateFrom) }}
                  - <br />
                   To :{{
                    changeFormatedDate(PatientReservationSurgery.SurgeryDateTo)
                  }}
                </div>

                <!-- DoctorSessionTime -->
                <div class="mt-1 fontSizetext" v-if="flag == 1">
                  <span
                    class="m-1 fontbold fontMedium"
                    v-if="
                      PatientReservationSession.DoctorSession.DateFrom != null &&
                      PatientReservationSession.DoctorSession.DateTo != null
                    "
                    >From:</span
                  >

                  {{ PatientReservationSession.DoctorSession.DateFrom.slice(11, 16) }}
                  <span
                    class="fontbold fontMedium m-1"
                    v-if="
                      PatientReservationSession.DoctorSession.DateFrom != null &&
                      PatientReservationSession.DoctorSession.DateTo != null
                    "
                  >
                    - To:</span
                  >
                  {{ PatientReservationSession.DoctorSession.DateTo.slice(11, 16) }}
                </div>
                <!-- Status -->
                <div class="mt-1 fontSizetext">
                  <span class="fontbold fontMedium m-1">Status:</span>
                  {{ operationObj.StatusNameEN }}
                </div>
              </div>

              <!-- paymentInfo -->
              <div class="vx-col lg:w-1/3 mt-5">
                <div class="mt-1 fontSizetext">
                <span class="fontbold fontMedium m-1" v-if="flag == 1">Price:</span>
                <span class="fontbold fontMedium m-1" v-else-if="flag == 2 && paymentObj.PaymentStatusID == 7">Remaining amount:</span>
                <span class="fontbold fontMedium m-1" v-else-if="flag == 2 && paymentObj.PaymentStatusID == 5">Total:</span>
                {{ flag == 2 && paymentObj.PaymentStatusID == 5 ? paymentObj.price + ProcessingFees : paymentObj.price }}
              </div>

                <div class="mt-1 fontSizetext" v-if="flag == 2 && paymentObj.PaymentStatusID == 7">
                  <span class="fontbold fontMedium m-1">deposit:</span>
                  {{ ProcessingFees }}
                </div>

                <div class="mt-1 fontSizetext" v-if="flag == 1">
                  <span class="fontbold fontMedium m-1">Currency:</span>
                  {{ paymentObj.CurrencyName }}
                </div>
                <div class="mt-1 fontSizetext">
                  <span class="m-1 fontbold fontMedium"> Status:</span>

                  {{ paymentObj.PaymentStatusNameEN }}
                </div>
                <div class="mt-5 fontSizetext">
                  <div
                    class="p-3"
                    v-if="paymentObj.PaymentStatusID == 1"
                    style="
                      box-sizing: border-box;
                      border-style: ridge;
                      border: 2px ridge #820032 !important;
                      color: #820032;
                      font-size: 16px;
                    "
                  >
                    <span class="fontbold fontMedium m-1"
                      >PleaseNote:
                      <br />
                    </span>
                    Pending Note
                  </div>
                  <div
                    class="p-3"
                    v-if="
                      paymentObj.PaymentStatusID == 2 ||
                      paymentObj.PaymentStatusID == 5 ||
                      paymentObj.PaymentStatusID == 7
                    "
                    style="
                      box-sizing: border-box;
                      border-style: ridge;
                      border: 2px ridge #820032 !important;
                      color: #820032;
                      font-size: 14px;
                    "
                  >
                    <span class="fontbold fontMedium m-1"
                      >Please Note:
                      <br />
                    </span>
                    your payment is received and confirmed !
                  </div>
                </div>
              </div>
            </div>
          </vs-card>
        </div>

        <!-- PDF Content Here -->
      </section>
    </vue-html2pdf>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
// import OnlinePayment from "@/views/pages/onilnepayment.vue";
import axios from "@/axios.js";
import moment from "moment";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";

// import UserNotLogin from "@/views/UserNotLogin.vue";
// import SetSession from "@/views/appointment/search/SetSession.vue";
// import SetPayment from "@/views/appointment/search/SetPayment.vue";
// import SetPatientNote from "@/views/appointment/search/SetPatientNote.vue";
// import UploadPatientMedicalReport from "@/views/Patient/UploadPatientMedicalReport.vue";

import modulePatient from "@/store/Patient/modulePatient.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
// import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
// import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";
// import moduleStatus from "@/store/settings/status/moduleStatus.js";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import html2canvas from "html2canvas";
import Canvas2Image from "canvas2image";

export default {
  data() {
    return {
      calender: new Date(),
      ModelCal: {
        start: new Date(),
        end: new Date(),
      },
      calenderData: new Date(),
      baseURL: domain,
      patientObj: {},
      doctorObj: {},
      operationObj: {},
      paymentObj: {},
      settingObj: {},
      PatientReservationSession: {},
      PatientReservationSurgery: {},
      CurrencyObj: {},
      PaymentStatusObj: {},
      StatusObj: {},
      flag: 0,
      ProcessingFees: "",
      uploadUrl: domain + "api/Common/UploadFile",
    };
  },
  computed: {
    BookingStatus() {
      //debugger;
      return this.$route.params.Flag;
    },
    OperationID() {
      if (this.flag == 1) {
        return "SessionID";
      } else if (this.flag == 2) {
        return "SurgeryID";
      }
      //return this.$route.params.ID;
    },
    Title() {
      if (this.flag == 1) {
        return "AppointmantBookingReservationDetails";
      } else if (this.flag == 2) {
        return "SurgeryBookingReservationDetails";
      }
    },
    TitleReservation() {
      if (this.flag == 1) {
        return "ReservationSession";
      } else if (this.flag == 2) {
        return "ReservationSurgrey";
      }
    },
  },
  components: {
    imageLazy,
    Datepicker,
    VueHtml2pdf,
    html2canvas,
    Canvas2Image,
    // OnlinePayment,
    // UserNotLogin,
    // SetSession,
    // SetPayment,
    // SetPatientNote,
    // UploadPatientMedicalReport,
  },
  methods: {
    OpenOnlineConsultations() {
      debugger;
      this.$router.push({ name: "PatientReservationSession" });
    },
    changeFormatedDate(date) {
      return moment(date).format("LL");
    },
    Back() {
      this.$router.push("/Patient/Home");
    },
    procced() {
      //debugger;
    },

    replacenumber()
    {
        debugger;
         if(this.patientObj.Phone !=null)
         {
        // this.patientObj.Phone.replace(/+,'');
        this.patientObj.Phone = this.patientObj.Phone.split('+').join("");
         }
    },
    fillInfoSession() {
      this.patientObj = {};
      debugger;
      this.patientObj.ProfileImagePath = this.PatientReservationSession.Patient.ProfileImagePath;
      debugger;
      this.patientObj.Name = this.PatientReservationSession.Patient.Name;
      this.patientObj.NameEN = this.PatientReservationSession.Patient.NameEN;
      this.patientObj.FamilyName = this.PatientReservationSession.Patient.FamilyName;
      debugger;
      this.patientObj.Address = this.PatientReservationSession.Patient.Address;
      if (this.PatientReservationSession.Patient.Country != null) {
       { this.patientObj.CountryName = this.PatientReservationSession.Patient.Country.Name;
        this.patientObj.CountryNameEN = this.PatientReservationSession.Patient.Country.NameEN;
      }
      } else {
        this.patientObj.CountryName = "";
       this.patientObj.CountryNameEN  = "";
      }
      debugger;
      this.patientObj.Phone = this.PatientReservationSession.Patient.Phone;
      this.patientObj.Email = this.PatientReservationSession.Patient.Email;
      debugger;

      this.operationObj.ID = this.PatientReservationSession.ID;
      this.operationObj.StatusID = this.PatientReservationSession.StatusID;

      this.operationObj.CreatedDate = this.PatientReservationSession.CreatedDate;
      if (
        this.PatientReservationSession.Status != null ||
        this.PatientReservationSession.Status != undefined
      )
        {
          this.operationObj.StatusName = this.PatientReservationSession.Status.Name;
          this.operationObj.StatusNameEN = this.PatientReservationSession.Status.NameEN;
        }
      this.paymentObj.price = this.PatientReservationSession.TotalPriceUSD;
      if (
        this.PatientReservationSession.DoctorSession.Currency != null ||
        this.PatientReservationSession.DoctorSession.Currency != undefined
      )
        this.paymentObj.CurrencyName = "USD";
      if (
        this.PatientReservationSession.PaymentStatus != null ||
        this.PatientReservationSession.PaymentStatus != undefined
      )
      {  this.paymentObj.PaymentStatusName = this.PatientReservationSession.PaymentStatus.Name;
        this.paymentObj.PaymentStatusNameEN = this.PatientReservationSession.PaymentStatus.NameEN;
      }
      this.paymentObj.PaymentStatusID = this.PatientReservationSession.PaymentStatusID;

      this.replacenumber();
    },
    fillInfoSurgery() {
      this.patientObj = {};
      debugger;
      this.patientObj.ProfileImagePath = this.PatientReservationSurgery.PatientProfileImagePath;
      this.patientObj.Name= this.PatientReservationSurgery.PatientName;
      this.patientObj.NameEN= this.PatientReservationSurgery.PatientName;
      debugger;
      this.patientObj.FamilyName = this.PatientReservationSurgery.PatientFamilyName;
      this.patientObj.Address = this.PatientReservationSurgery.PatientAddress;
      this.patientObj.CountryName = this.PatientReservationSurgery.PatientCountry;
      this.patientObj.Phone = this.PatientReservationSurgery.PatientPhone;
      this.patientObj.Email = this.PatientReservationSurgery.PatientEmail;

      this.operationObj.ID = this.PatientReservationSurgery.ID;
      this.operationObj.StatusID = this.PatientReservationSurgery.StatusID;

      this.operationObj.CreatedDate = this.PatientReservationSurgery.SurgeryCreatedDate;
      this.operationObj.StatusName = this.PatientReservationSurgery.StatusName;
      this.operationObj.StatusNameEN = this.PatientReservationSurgery.StatusNameEN;
      this.paymentObj.price = this.PatientReservationSurgery.Price;
      this.ProcessingFees =
        this.PatientReservationSurgery.ProcessingFees +
        this.PatientReservationSurgery.VAT;
      //this.paymentObj.CurrencyName =this.PatientReservationSurgery.DefaultHospitalPrice;
      this.paymentObj.PaymentStatusName = this.PatientReservationSurgery.PaymentStatusName;
      this.paymentObj.PaymentStatusNameEN = this.PatientReservationSurgery.PaymentStatusNameEN;
      this.paymentObj.PaymentStatusID = this.PatientReservationSurgery.PaymentStatusID;
    },
    ConvertToPDF() {
      debugger;

      // this.PatientReservationSession.Doctor.ProfileImagePath;
      this.$refs.html2Pdf.generatePdf();
    },
    GeneratInvoice() {
      debugger;
      html2canvas(document.querySelector(".canvImg")).then((canvas) => {
        debugger;
        document.body.appendChild(canvas);
        debugger;
        canvas = document.getElementsByTagName("canvas")[0].toDataURL();
        debugger;
        var ref;
        if (this.flag == 1) {
          ref = this.PatientReservationSession.ReferenceNumber;
        } else {
          ref = this.PatientReservationSurgery.ReferenceNumber;
        }
        debugger;
        var InvoiceModel = {
          email: this.patientObj.Email,
          InvoiceRef: ref,
          messageBpdy: canvas,
          image: canvas,
        };
        //element.remove()
        document.getElementsByTagName("canvas")[0].outerHTML = "";
        // canvas.remove();
        debugger;
        // this.$store
        //   .dispatch("patientList/SendInvoiceEmail", InvoiceModel)
        //   .then((res) => {
        //     if (res.status == 200) {
        //       debugger;
        //       window.showAddSuccess();
        //     }
        //   })
        //   .catch(() => {
        //     debugger;
        //     window.showError();
        //   });
      });
      debugger;

      this.$refs.Invoice2Pdf.generatePdf();
    },
    getSessionData(){
         this.$store
        .dispatch("DoctorList/GetPatientReservationSessionAndSendNotification", this.$route.params.ID)
        .then((res) => {
          this.PatientReservationSession = res.data.Data;
          debugger;
          this.fillInfoSession();
          debugger;
          this.calender = this.PatientReservationSession.DoctorSession.DateFrom;
          this.calenderData = this.PatientReservationSession.DoctorSession.DateFrom;

          if (this.PatientReservationSession.VAT == null) {
            this.PatientReservationSession.VAT = 0;
          }
          const datepicker2 = this.$refs.datepicker2;
          // Call method of the component
          datepicker.value_ = new Date(res.data.Data.DoctorSession.DateFrom);
        });
    },
    getSurgeryData(){
       var vm = this;
      this.$store
        .dispatch("patientList/GetPatientReservationSurgery", this.$route.params.ID)
        .then((res) => {
          debugger;
          this.PatientReservationSurgery = res.data.Data;
          if (
            this.PatientReservationSurgery.VAT == null ||
            this.PatientReservationSurgery.VAT == 0
          ) {
            this.PatientReservationSurgery.VAT = 0;
          }
          this.fillInfoSurgery();
          this.replacenumber();
          debugger;
          this.calenderData = res.data.Data.SurgeryDateFrom;
          // vm.$refs.datepicker.updateOptions({ start: res.data.Data.SurgeryDateFrom, end : res.data.Data.SurgeryDateTo });
          vm.ModelCal = {};
           vm.ModelCal.start = res.data.Data.SurgeryDateFrom;
           vm.ModelCal.end = res.data.Data.SurgeryDateTo;

          // var asd = new Date(res.data.Data.SurgeryDateFrom);

          // vm.$set(vm.ModelCal, "start", new Date(res.data.Data.SurgeryDateFrom));

          // //  vm.ModelCal.end =new Date(res.data.Data.SurgeryDateTo);

          // vm.$set(vm.ModelCal, "end", new Date(res.data.Data.SurgeryDateTo));

          // const datepicker = this.$refs.datepicker;
          // // Call method of the component
          // vm.$refs.datepicker.value_.start = new Date(res.data.Data.SurgeryDateFrom);
          // vm.$refs.datepicker.value_.end = new Date(res.data.Data.SurgeryDateTo);

          // vm.$refs.datepicker._data.value_.start = new Date(res.data.Data.SurgeryDateFrom);
          // vm.$refs.datepicker._data.value_.end = new Date(res.data.Data.SurgeryDateTo);
          // debugger;
        });
    }
  },
  created() {
    debugger;
    //type of data viewer appointment or surgery
    //flag==1 appointment
    //flag==2 surgery
    this.flag = this.$route.params.Flag;

    debugger;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      debugger;
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    debugger;
    var url_string = window.location.href; //window.location.href
var url = new URL(url_string);
var tap_id = url.searchParams.get("tap_id");

    if (this.flag == 1) {
      if(tap_id!=null&&tap_id!=undefined&&tap_id!=""){
  var model = {};
  model.appointmentID = this.$route.params.ID;
 // model.surgeryReservistionID = this.$route.params.ID;
  model.OrderID = tap_id;

  this.$store.dispatch("patientList/GetAppointMentTapPayment", model).then(res=>{
    debugger
     this.getSessionData()

    if(res.data.Data.Item1){
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("PaymentSuccess")
      });
    }
    else{
      this.$vs.notify({
        color: "danger",
        position: "bottom-center",
        title: this.$t("Failed"),
        text: this.$t("PaymentFailed")
      });
    }
  })
}
else{
 this.getSessionData()
}
      this.PatientReservationSurgery = {};
     } else if (this.flag == 2) {
      debugger;
       if(tap_id!=null&&tap_id!=undefined&&tap_id!=""){
  var model = {};
 // model.appointmentID = this.$route.params.ID;
  model.surgeryReservistionID = this.$route.params.ID;
  model.OrderID = tap_id;

  this.$store.dispatch("patientList/GetSurgeryTapPayment", model).then(res=>{
    debugger
     this.getSurgeryData()

    if(res.data.Data.Item1){
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("PaymentSuccess")
      });
    }
    else{
      this.$vs.notify({
        color: "danger",
        position: "bottom-center",
        title: this.$t("Failed"),
        text: this.$t("PaymentFailed")
      });
    }
  })
}
else{
  this.getSurgeryData();
}

    }
    //debugger;
    this.procced();
  },
  mounted() {},
};
</script>

<style>
#bookSummery .doctorImage {
  width: 100px;
  height: 120px;
  text-align: end;
}
#bookSummery .custCardHead {
  font-weight: bold;
  font-size: 1.4rem;
  color: #004477;
}
#bookSummery .custHead {
  font-weight: bold;
  font-size: 1.7rem;
  text-align: center;
  color: #004477;
}

#bookSummery .text-1xl {
  color: #393939;
  font-size: 1rem;
  letter-spacing: 0px;
  opacity: 0.5;
}
#bookSummery .fontSizetext {
  font-size: 14px !important;
}
#bookSummery .vs-popup--content {
  height: calc(100vh - 100px);
}
#bookSummery .con-vs-popup .vs-popup {
  width: 100% !important;
  height: 100vh;
}
#bookSummery .vx-card {
  padding: 0px !important;
}
#bookSummery .vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 1rem 0 1rem 0 !important;
}
.desSendTable {
  height: 2.5rem;
  text-align: left;
}
</style>
